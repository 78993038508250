<template>
    <div>
      <section class="tables">
        <div class="row">
          <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="card-title">
                  Ajouter une demande
                  <router-link to="/demandeechantillon">
                    <span class="float-right btn btn-info"> retour </span>
                  </router-link>
                </div>
                <div class="container">
                  <form @submit.prevent="adddemande()">
                    <div class="row">
                     
                      
                      <div class="col-md-5">
                        <div class="form-group">
                          <label for="last"
                            >Choisir le delégué
                            <span class="obligatoire">*</span></label
                          >
  
                          <v-select
                            :multiple="false"
                            :closeOnClick="false"
                            v-model="demande.user_id"
                            :options="users"
                            :reduce="(users) => users._id"
                            required
                            label="name"
                          />
                        </div>
                      </div>
                      
                      <div class="col-md-5">
                        <div class="form-group">
                          <label for="last"
                            >Choisir les produits
                            <span class="obligatoire">*</span></label
                          >
  
                          <v-select
                            :multiple="true"
                            :closeOnClick="false"
                            v-model="demande.produits"
                            :options="produits"
                            :reduce="(produits) => produits"
                            required
                            label="reference"
                          />
                        </div>
                      </div>
                      <div class="col-md-12" v-if="demande.produits.length != 0">
                        <div class="form-group">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>Réference du produit</th>
                                <th>Quantités</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(produit, index) in demande.produits"
                                :key="index"
                              >
                                <td>
                                  {{ produit.reference }}
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="produit.quantites"
                                    required
                                :min="1"

                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <br />
                          <button type="submit" class="btn btn-primary"
                          :disabled="demande.produits.length == 0 || demande.user_id.length==0"
                          
                          
                          >
                            + Ajouter la demande
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  import { HTTP } from "@/axios";
  import Swal from "sweetalert2";
  
  import moment from "moment";
  export default {
    data() {
      return {
        demande: {
          user_id: "",
          produits: [],
        },
        users: [],
        clients: [],
        error: "",
      };
    },
  
    created() {
      this.getproduits();
      this.getusers();
    },
    methods: {
      getusers() {
        HTTP.get("users/getusersdelegue")
          .then((response) => {
            this.users = response.data;
          })
          .then((err) => console.log(err));
      },
  
     
      getproduits() {
        HTTP.get("produits/getproduitfields")
          .then((response) => {
            this.produits = response.data;
            this.produits.forEach((produit) => {
              produit.quantites = "";
              produit.produit_id = produit._id;
            });
          })
          .then((err) => console.log(err));
      },
  
      adddemande() {
        this.demande.produits.forEach((produit) => {
          delete produit.reference;
        });
        HTTP.post("echantillons/addEchantillon", this.demande).then(
          (response) => {
            console.log(response);
            Toast.fire({
              icon: "success",
              title: "demande ajouté",
            });
  
            this.$router.push("/demandeechantillon");
          },
          (err) => {
            console.log(err);
          }
        );
      },
    },
  };
  </script>
  